import {
  Button,
  CheckBox,
  Heading,
  Icon,
  InputText,
  Label,
  Link as LinkText,
  Paragraph,
} from "@reamrd/styledoubler";
import { Auth } from "aws-amplify";
import { Form, Formik } from "formik";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { countryOptions } from "../mappings/mappings";
import { readFromLocalStorage } from "../tools/client-storage";
import { SDCountry } from "../types";

const RegisterPage = () => {
  let navigate = useNavigate();

  const [accept, setAccept] = React.useState(false);
  const [errorRegistrationMessaage, setErrorRegistrationMessaage] =
    React.useState("");

  const { country } = readFromLocalStorage<{ country: SDCountry }>("country");

  const signUp = async (values: any) => {
    try {
      await Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
        },
      });

      navigate("/register/confirm", {
        state: {
          email: values.email,
          password: values.password,
        },
      });
    } catch (error: any) {
      console.log(error);

      const visibleError =
        error.code === "UsernameExistsException"
          ? "Username is already taken"
          : "Oops, something wrong";

      setErrorRegistrationMessaage(visibleError);
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string()
      .matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/,
        "Password should contain at least one uppercase, lowercase and one number"
      )
      .min(8, "Password should contain 8 characters or more")
      .required("Required"),
    passwordConfirm: Yup.string()
      .when("password", (password: string, schema: any) => {
        return schema.test({
          test: (passwordConfirm: any) =>
            !!password && password === passwordConfirm,
          message: "Password mismatch",
        });
      })
      .required("Required"),
  });

  const countryIcon = countryOptions.find(
    (x) => x.value === country
  )!.customIcon;

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <div className="pt-16 w-full px-4">
        <div className="leading-7">
          <Heading variant="H4" text="Create A StyleDoubler Account" />
        </div>

        <div className="pt-3 leading-5">
          <Paragraph
            variant="P2"
            text="To start earning money and applying to campaigns, you have to create a StyleDoubler account first."
          />
        </div>
      </div>
      <div className="flex-1">
        <Formik
          initialValues={{
            email: "",
            password: "",
            passwordConfirm: "",
          }}
          onSubmit={signUp}
          validationSchema={validationSchema}
        >
          {({ ...x }) => {
            return (
              <Form>
                <div className="px-4">
                  <div className="pt-8">
                    <InputText
                      value={x.values.email}
                      onChange={x.handleChange("email")}
                      title="Email"
                      placeholder="example@mail.com"
                      required={true}
                      isTouched={!!x.touched.email}
                      validationError={x.errors.email}
                    />
                  </div>
                  <div className="mt-4">
                    <InputText
                      value={x.values.password}
                      onChange={x.handleChange("password")}
                      title="Password"
                      placeholder="Enter Password"
                      required
                      isPassword
                      isTouched={!!x.touched.password}
                      validationError={x.errors.password}
                    />
                  </div>
                  <div className="my-4">
                    <InputText
                      value={x.values.passwordConfirm}
                      onChange={x.handleChange("passwordConfirm")}
                      title="Confirm Password"
                      placeholder="Enter Password"
                      required
                      isPassword
                      isTouched={!!x.touched.passwordConfirm}
                      validationError={
                        !!errorRegistrationMessaage
                          ? errorRegistrationMessaage
                          : x.errors.passwordConfirm
                      }
                    />
                  </div>

                  <div className="mt-4 mb-2 flex flex-row items-center justify-between">
                    <div className="">
                      <CheckBox
                        title="I Accept StyleDoubler’s "
                        initiallyChecked={false}
                        checked={accept}
                        onChange={setAccept}
                      />
                    </div>
                    <div className="mt-0.5">
                      <LinkText
                        variant="NK1" 
                        text="Terms of Service"
                        href="https://www.styledoubler.com/terms-of-service/"
                      />
                    </div>
                  </div>
                  <div className="mt-6 mb-2 flex flex-col">
                    <Button
                      type="submit"
                      disabled={
                        (!!Object.keys(x.errors).length &&
                          !!Object.keys(x.touched).length) ||
                        !accept
                      }
                      actionInProgress={x.isSubmitting}
                      onClick={x.handleSubmit}
                      text="Create Account"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="mt-3 pb-5 w-full flex flex-row items-center justify-center">
        <div
          onClick={() =>
            navigate("/register/country", {
              state: {},
            })
          }
          className="px-2 border border-sd-purple-300 z-50 mt-6 mb-3 py-2 rounded-lg flex flex-row justify-center items-center"
        >
          <div className="mr-2">{countryIcon("sm")}</div>
          <Label
            variant="L4"
            text={countryOptions.find((x) => x.value === country)?.title || ""}
          />

          <div className="ml-6 mr-2">
            <Icon name="ChevronDownIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
