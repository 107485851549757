import { Icon } from "@reamrd/styledoubler";
import { SDCountry } from "../types";

const countryOptions: {
  title: string;
  customIcon: (size: "xs" | "sm" | "md") => JSX.Element;
  value: SDCountry;
}[] = [
  {
    title: "Indonesia",
    customIcon: (size) => <Icon name="Indonesia" size={size} />,
    value: "id",
  },
  {
    title: "Malaysia",
    customIcon: (size) => <Icon name="Malaysia" size={size} />,
    value: "my",
  },
  {
    title: "Philippines",
    customIcon: (size) => <Icon name="Philippines" size={size} />,
    value: "ph",
  },
  {
    title: "Thailand",
    customIcon: (size) => <Icon name="Thailand" size={size} />,
    value: "th",
  },
  {
    title: "Singapore",
    customIcon: (size) => <Icon name="Singapore" size={size} />,
    value: "sg",
  },
];

export { countryOptions };
